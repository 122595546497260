<template>
  <div class="job-category-info wrap">
    <UiHeading
      :h="'2'"
      :type="'A'"
      :text="'Job Conditions'"
      :subTitle="'求人情報'"
    />
    <div class="table-inner">
      <table>
        <tr>
          <th>職種</th>
          <td>{{ job }}</td>
        </tr>
        <tr>
          <th>雇用形態</th>
          <td>パートタイム・アルバイト（雇用期間の定めなし）</td>
        </tr>
        <tr>
          <th>業務内容</th>
          <td v-html="content"></td>
        </tr>
        <tr>
          <th>勤務場所</th>
          <td>岐阜市加納桜田町2-5-2（転勤なし）</td>
        </tr>
        <tr>
          <th>応募条件</th>
          <td v-html="condition"></td>
        </tr>
        <tr>
          <th>必要な資格</th>
          <td>普通自動車免許（AT限定可）</td>
        </tr>
        <tr>
          <th>時給</th>
          <td v-html="pay">
          </td>
        </tr>
        <tr></tr>
        <tr>
          <th>昇給実績</th>
          <td>
            {{ raise }}
          </td>
        </tr>
        <tr>
          <th>通勤手当</th>
          <td>実費支給（マイカー通勤・駐車場完備）<br>※ご自宅からの距離に応じたガソリン代を支給。公共交通機関・自転車・徒歩での通勤の場合も、ご自宅からの距離に応じたガソリン代で交通費を支給となります。</td>
        </tr>
        <tr>
          <th>勤務時間</th>
          <td>
            平日9時30分～17時のご希望の曜日・時間帯で週12時間以上（※月間120時間未満）
          </td>
        </tr>
        <tr>
          <th>会社年間休日</th>
          <td>120日以上（土日祝、年末年始）</td>
        </tr>
        <tr>
          <th>試用期間</th>
          <td>300時間（勤務態度・評価によって延長の可能性あり）</td>
        </tr>
        <tr>
          <th>加入保険</th>
          <td>雇用保険（週20時間以上の勤務の場合）</td>
        </tr>
      </table>
      <p v-show="pg === true">
        プログラマー（経験者）は、スキルと勤務評価に応じて部分的な在宅勤務を認めています。
      </p>
      <p v-show="wdn === true">
        ウェブデザイナー（経験者）は、スキルと勤務評価に応じて部分的な在宅勤務を認めています。
      </p>
      <p v-show="first === true">
        当求人に応募を希望の場合は、当ページに記載の内容を必ずすべてお読みいただき、仕事内容や応募条件をご理解の上、ご応募をお願いします。
      </p>
    </div>
    <div class="detail-button" href="#" v-scroll-to="'#contact'">応募する</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
export default {
  name: "UiTable4",
  components: { UiHeading },
  props: {
    job: String,
    content: String,
    condition: String,
    pay: String,
    raise: String,
    pg: Boolean,
    wdn: Boolean,
    first: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.table-inner {
  margin-top: 50px;
}
table {
  width: 100%;
  padding: 0 83px;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: 1px var(--black) solid;
      padding: 10px;
      @media screen and (max-width: 767px) {
        display: block;
        border-bottom: none;
      }
    }
    th {
      text-align: center;
      vertical-align: middle;
      width: 15%;
      background-color: rgb(226, 226, 226);
      @media screen and (max-width: 767px){
        width: 100%;
      }
    }
    td {
      text-align: left;
      width: 85%;
      padding-left: 30px;
      /deep/ul{
        margin-left: 20px;
        li{
          list-style-type: disc;
        }
      }
            @media screen and (max-width: 767px){
        width: 100%;
        padding-left: 10px;
      }
    }
  }
      tr:last-child td{
     @media screen and (max-width: 767px){
       border-bottom: solid 1px var(--black);
     }
  }
}
.detail-button {
  border: var(--sub) 1px solid;
  width: 200px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  color: var(--sub);
  transition: 0.8s;
  &:hover {
    cursor: pointer;
    color: white !important;
    background-color: var(--sub);
  }
}

</style>