<template>
  <div class="home">
    <div class="job-category-top">
      <div class="blue-back">
        <div class="wrap blue-inner">
          <p class="job-category-title">Corder</p>
          <h1 class="job-category-sub">ウェブサイト制作（経験者）の求人情報</h1>
        </div>
      </div>
      <div class="wrap">
        <img
          alt="ウェブサイト制作トップ"
          src="@/assets/job-category/GH-kyujin-website001.webp"
          class="top"
        />
        <div class="text">
          <p>
            HTML/CSSやWordPressによるウェブサイト制作のお仕事で、時間的な自由を求める人は、ぜひ株式会社グレイトヘルプの求人をチェックしてみてください。<br />弊社は、ウェブ制作のコード担当として、家事や子育てに忙しい主婦・主夫、フリーランス・個人事業主としてご自身のお仕事をしながら勤務される方が主となって活躍する職場です。<br />出勤曜日が自由、勤務時間が自由、無駄な拘束時間なしなど、仕事において時間的な制約がある人が働きやすい勤務条件と職場環境を整えています。
          </p>
          <img src="@/assets/ezgif.com-gif-maker-A.png" alt="説明イラスト" />
        </div>
        <img
          src="@/assets/ezgif.com-gif-maker-B.png"
          alt="雲"
          class="illust-b"
        />
      </div>
    </div>

    <div class="recommendation">
      <HomeRecommendation />
    </div>

    <div class="space-top-70 space-top-130">
      <HomeWorkPlace />
    </div>

    <div class="space-top-130">
      <HomeWorkEnvironment />
    </div>

    <div class="space-top-130">
      <HomeWorkEnvironment2 />
    </div>

    <div class="space-top-130">
      <JobCategoryAboutWork
        :topImg="false"
        :middleImg="true"
        :imgPath1="'GH-kyujin-website002.webp'"
        :imgPath2="'GH-kyujin-website003.webp'"
        :subTitle="'ウェブサイト制作のお仕事について'"
        :detail="'弊社では、クライアントからの受託開発系のホームページ制作と、自社サービスに関するホームページの制作の業務があります。HTML/CSSによるホームページのコーディングやWordPressでのホームページの構築をしていただきます。業務の指示として、マーケティング部門がホームページの設計図と文章を用意し、それをもとにデザイナーがホームページのデザインをし、これらを用いて、ホームページをコーディングして形にしていただきます。クライアントと打ち合わせをすることは基本的にありません。事務所内でのパソコンと向き合ったお仕事となります。'"
        :skill="'ホームページ制作の実務経験2年以上と、HTML/CSSのスキルは必須です。WordPressのスキルや経験もあると良いです。デザインやホームページの設計のスキルや経験は不問です。基本的にコーディング業務のみをしていただき、入社後の勤務の様子や希望をもとに、ホームページの設計業務や、より高度なプログラミングの業務を習得していただきます。<br><br>もっとも求める能力は、「指示やルールを守る素直さ」です。社内の上司から教育や指示があるので、その内容を素直に受け止められる姿勢が非常に重要です。<br>また、コードの記述方法や業務対応は、社内の方針にそって行っていただくため、この点からも、職場に合わせる柔軟性と素直さが必要になります。<br>そのため、「自分のやり方を貫きたい」「周りに合わせる気がない」という人は合わない職場です。<br>上述のとおりコーディングのスキルは必須ですが、それと同じく、仕事を丁寧・正確に遂行する能力も必要となります。デザイナーの作ったデザインどおりにコーディングすることは必須です。<br>マーケティング部門とデザイン部門と協力して業務を進めるため、コミュニケーション能力と部門間での柔軟な対応の姿勢も必要となります。'"
        :experience="'サイト制作の技術として、以下が身につきます。<ul><li>CSSのより効率的な書き方（SCSS）</li><li>WordPress</li><li>フレームワーク（Vue.js）の正しい使い方</li><li>動くウェブサイト（アニメーションデザイン）の作り方</li></ul><br>弊社はウェブマーケティングに強みがあり、制作する媒体を通して事業主様の売上アップや集客増などの業績向上を支援しています。そのため、弊社でウェブサイト制作の業務を経験することで、ただ媒体やシステムを構築するだけでなく、売上アップや集客増などのマーケティングを考慮した媒体やシステムの構築スキルが身につきます。特にSEOを考慮したホームページの制作ノウハウが身につくことは、大きなメリットです。'"
      />
    </div>

    <div class="space-top-130 space-top-70">
      <JobCategoryInfo
        :job="'ウェブサイト制作（経験者）'"
        :content="'HTML/CSSによるホームページの制作業務など'"
        :condition="'ウェブサイト制作の実務経験2年以上'"
        :pay="'1,200円～1,600円<br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,200円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。'"
        :raise="'20円〜100円/時（社内の全職種による実績）'"
        :pg="false"
        :wdn="false"
      />
    </div>
    <div class="space-top-130 space-top-70">
      <HomeSelectionSchedule />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeQa :qaBoxList="qaBoxList" />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeContact id="contact" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeRecommendation from "@/components/Home/Recommendation.vue";
import HomeWorkPlace from "@/components/Home/WorkPlace.vue";
import HomeWorkEnvironment from "@/components/Home/WorkEnvironment.vue";
import HomeWorkEnvironment2 from "@/components/Home/WorkEnvironment2.vue";
import JobCategoryAboutWork from "@/components/JobCategory/AboutWork.vue";
import JobCategoryInfo from "@/components/JobCategory/Info.vue";

import HomeSelectionSchedule from "@/components/Home/SelectionSchedule.vue";
import HomeQa from "@/components/Home/Qa.vue";
import HomeContact from "@/components/Home/Contact.vue";

export default {
  name: "Home",
  components: {
    HomeRecommendation,
    HomeWorkPlace,
    HomeWorkEnvironment,
    HomeWorkEnvironment2,
    JobCategoryAboutWork,
    JobCategoryInfo,
    HomeSelectionSchedule,
    HomeQa,
    HomeContact,
  },
  props: {},
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion: "自由出勤の仕組みについて教えてください。自由に休めますか？",
          answer:
            "他の従業員と業務の進捗への影響がないことを前提にした勤務時間の自由となります。入社時に、1週間あたりの勤務時間（週12時間～）を決定します。この勤務時間において、出勤曜日と勤務時間が自由という仕組みです。出勤予定日にお休みをとった場合は、原則として1週間以内に欠勤分の勤務時間を補填する勤務をしていただいています（欠勤の理由と業務状況によっては補填なしの場合もあり）。お子様の長期休暇時は、事前の申請と調整によって、勤務日数・勤務時間を通常よりも減らすことは可能です。この条件を満たせる方のみご応募可能となっています。",
        },
        {
          id: 2,
          qestion: "未経験でも応募可能でしょうか？",
          answer:
            "ウェブサイト制作の実務経験2年以上に限って応募可能です。未経験者および経験2年未満の方は「<a href='https://recruit.greathelp.co.jp/web01/'>ウェブサイト制作（未経験者～経験2年未満）</a>」の求人をご確認ください。",
        },
        {
          id: 3,
          qestion: "どの程度のコーディングスキルが必要となりますか？",
          answer:
            "デザイナーが用意したデザインデータどおりに、ご自身で1からホームページをコーディングし、完成させられるレベルのスキルが必要です。",
        },
        {
          id: 4,
          qestion: "使用する端末のOSは何でしょうか？",
          answer: "WindowsまたはMacでご希望の方の端末をご用意します。",
        },
        {
          id: 5,
          qestion: "副業・フリーランスでの応募も可能でしょうか？",
          answer:
            "平日9時30分～17時のご希望の曜日・時間帯で週12時間以上の勤務ができる場合は応募可能です。",
        },
        {
          id: 6,
          qestion: "在宅勤務・テレワークは可能でしょうか？",
          answer:
            "事務所への出社での勤務となっています。緊急事態宣言時などのみ行う場合があり、在宅勤務は原則としてございません。在宅勤務やテレワークをご希望の場合は、外注としてのお取引が可能な場合がありますので、詳細はお問い合わせをお願いします。",
        },
        {
          id: 7,
          qestion: "グレイトヘルプに勤務しながら、副業は可能でしょうか？",
          answer:
            "可能です。ただし、弊社での勤務がメインであることを前提とします。また、弊社の事業内容と競合する仕事などは制限がある場合がございます。",
        },
        {
          id: 8,
          qestion: "業務中に車を運転することはありますか？",
          answer:
            "職種によってはあります。頻度はまれです。社有車があります。ご自身のお車を使う場合は手当を支給しています。業務中にお車の運転ができないことを前提とした応募も可能ですので、応募書類にその旨をご記載ください。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.job-category-top {
  .blue-back {
    background-color: var(--main);
    .blue-inner {
      position: relative;
      padding-top: 100px;
      padding-bottom: 150px;
      @media screen and (max-width: 767px) {
        margin-top: 70px;
      }
      h1,
      p {
        color: white;
      }
    }
  }
  .top {
    margin-top: -120px;
  }
  .text {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
    @media screen and (max-width: 767px) {
      display: block;
    }
    p {
      width: 80%;
      margin-bottom: 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    img {
      width: 250px;
      @media screen and (max-width: 767px) {
        width: 200px;
        margin-left: calc(100% - 200px);
      }
    }
  }
  .illust-b {
    width: 200px;
    position: absolute;
    top: 60%;
    left: 15%;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.recommendation {
  background-color: var(--sub);
}
</style>