<template>
  <div class="home">
    <div class="job-category-top">
      <div class="blue-back">
        <div class="wrap blue-inner">
          <p class="job-category-title">Programmer</p>
          <h1 class="job-category-sub">プログラマー（経験者）の求人情報</h1>
        </div>
      </div>
      <div class="wrap">
        <img
          alt="プログラマートップ"
          src="@/assets/job-category/GH-kyujin-programmer001.webp"
          class="top"
        />
        <div class="text">
          <p>
            時間的な自由があるプログラマーのお仕事をお探しの人は、ぜひ株式会社グレイトヘルプの求人をチェックしてみてください。<br />
            弊社は、プログラマーとして、家事や子育てに忙しい主婦・主夫、フリーランス・個人事業主としてご自身のお仕事をしながら勤務される方が主となって活躍する職場です。<br />
            出勤曜日が自由、勤務時間が自由、無駄な拘束時間なし、勤務評価によって在宅勤務OKなど、仕事において時間的な制約がある人が働きやすい勤務条件と職場環境を整えています。
          </p>
          <img src="@/assets/ezgif.com-gif-maker-A.png" alt="説明イラスト" />
        </div>
        <img
          src="@/assets/ezgif.com-gif-maker-B.png"
          alt="雲"
          class="illust-b"
        />
      </div>
    </div>

    <div class="recommendation">
      <HomeRecommendation />
    </div>

    <div class="space-top-70 space-top-130">
      <HomeWorkPlace />
    </div>

    <div class="space-top-130">
      <HomeWorkEnvironment />
    </div>

    <div class="space-top-130">
      <HomeWorkEnvironment2 />
    </div>

    <div class="space-top-130">
      <JobCategoryAboutWork
        :topImg="true"
        :middleImg="false"
        :subTitle="'プログラマーのお仕事について'"
        :imgPath1="'GH-kyujin-programmer002.webp'"
        :imgPath2="'GH-kyujin-programmer003.webp'"
        :detail="'弊社は、クライアントからの受託開発系の業務として、ホームページ制作、ECサイト制作、業務系のウェブシステムなどのプログラミング業務と、自社サービスとして開発して販売しているシステムのプログラミング業務があります。<br>システムの開発規模は比較的小さい案件が主のため、一人で1システムを完成させるお仕事が多いです。<br>受託開発系の案件は、すべての仕事がクライアントから直接受注です。また、きつきつの納期で仕事に追われる性質の仕事はありません。クライアントとしっかりと納期を調整のもと仕事を受注していますし、マーケティングでクライアントに価値提供できる会社であるため、クライアントとの関係も良好です。<br>クライアントとの打ち合わせや要件定義は、プログラミング部門の上司が行いますので、基本的にはパソコンに向かってプログラムをしていただくお仕事になります。経験と希望に応じて、プログラミングの設計業務やクライアントとの打ち合わせや要件定義の業務もお願いすることになります。できる方には、ぜひお願いしたいです。'"
        :skill="'必要となるスキルとして、ウェブ系のプログラミングの実務経験3年以上と、Laravel、Cakeなどのフレームワークの使用経験です。そして、それ以上に大切なことは、「指示やルールを守る素直さ」です。<br>弊社のお仕事では、弊社の指示とルールをそって業務をしていただきます。そのため、これまで身につけたご自身のプログラムの記述、業務の対応スタンスに固執することがない素直さと柔軟性が非常に重要です。<br>そのため、「自分のやり方を貫きたい」「周りに合わせる気がない」という人は合わない職場です。<br>また、業務をご自身で完結させる責任感のある姿勢と、丁寧で正確な業務遂行の姿勢もとても重要です。この姿勢に欠ける場合は、そもそもプログラマーという仕事に合っていないと考えています。<br>シンプルなことですが、上述の姿勢を採用において重要視しています。'"
        :experience="'弊社のプログラミング業務を通して、以下が身につきます。<ul><li>PHP</li><li>フレームワークの正しい使い方</li><li>バグを起こしにくいコードの書き方</li><li>保守、拡張などに強い、柔軟性の高いコードの書き方</li><li>DBの正しい設計方法</li><li>基本的なWebセキュリティ</ul><br>弊社はウェブマーケティングに強みがあり、制作する媒体を通して事業主様の売上アップや集客増などの業績向上を支援しています。そのため、弊社でプログラミング業務を経験することで、ただ媒体やシステムを構築するだけでなく、売上アップや集客増などのマーケティングを考慮した媒体やシステムの構築スキルが身につきます。'"
      />
    </div>

    <div class="space-top-130 space-top-70">
      <JobCategoryInfo
        :job="'プログラマー（経験者）'"
        :content="'HTML・CSS・PHP・Javascriptなどによるウェブプログラミング業務など'"
        :condition="'プログラミングの実務経験3年以上'"
        :pay="'1,400円～1,800円 <br>※試用期間中は時給1,400円です。試用期間終了後にスキル・勤務評価によって時給を決定します。'"
        :raise="'20円〜100円/時（社内の全職種による実績）'"
        :pg="true"
        :wdn="false"
      />
    </div>
    <div class="space-top-130 space-top-70">
      <HomeSelectionSchedule />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeQa :qaBoxList="qaBoxList" />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeContact id="contact" />
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeRecommendation from "@/components/Home/Recommendation.vue";
import HomeWorkPlace from "@/components/Home/WorkPlace.vue";
import HomeWorkEnvironment from "@/components/Home/WorkEnvironment.vue";
import HomeWorkEnvironment2 from "@/components/Home/WorkEnvironment2.vue";
import JobCategoryAboutWork from "@/components/JobCategory/AboutWork.vue";
import JobCategoryInfo from "@/components/JobCategory/Info.vue";

import HomeSelectionSchedule from "@/components/Home/SelectionSchedule.vue";
import HomeQa from "@/components/Home/Qa.vue";
import HomeContact from "@/components/Home/Contact.vue";

export default {
  name: "Home",
  components: {
    HomeRecommendation,
    HomeWorkPlace,
    HomeWorkEnvironment,
    HomeWorkEnvironment2,
    JobCategoryAboutWork,
    JobCategoryInfo,
    HomeSelectionSchedule,
    HomeQa,
    HomeContact,
  },
  props: {},
  data() {
    return {
      qaBoxList: [
                {
          id: 1,
          qestion: "自由出勤の仕組みについて教えてください。自由に休めますか？",
          answer:
            "他の従業員と業務の進捗への影響がないことを前提にした勤務時間の自由となります。入社時に、1週間あたりの勤務時間（週12時間～）を決定します。この勤務時間において、出勤曜日と勤務時間が自由という仕組みです。出勤予定日にお休みをとった場合は、原則として1週間以内に欠勤分の勤務時間を補填する勤務をしていただいています（欠勤の理由と業務状況によっては補填なしの場合もあり）。お子様の長期休暇時は、事前の申請と調整によって、勤務日数・勤務時間を通常よりも減らすことは可能です。この条件を満たせる方のみご応募可能となっています。",
        },
        {
          id: 2,
          qestion: "未経験でも応募可能でしょうか？",
          answer:
            "プログラミングの実務経験3年以上に限って応募可能です。未経験者および経験3年未満の方は「<a href='https://recruit.greathelp.co.jp/pg01/'>プログラマー（未経験者～経験3年未満）</a>」の求人をご確認ください。",
        },
        {
          id: 3,
          qestion: "副業・フリーランスでの応募も可能でしょうか？",
          answer:
            "平日9時30分～17時のご希望の曜日・時間帯で週12時間以上の勤務ができる場合は応募可能です。",
        },
        {
          id: 4,
          qestion: "在宅勤務・テレワークは可能でしょうか？",
          answer:
            "プログラマー（経験者）はスキルと勤務評価に応じて部分的な在宅勤務を認めています。",
        },
        {
          id: 5,
          qestion: "グレイトヘルプに勤務しながら、副業は可能でしょうか？",
          answer:
            "可能です。ただし、弊社での勤務がメインであることを前提とします。また、弊社の事業内容と競合する仕事などは制限がある場合がございます。",
        },
        {
          id: 6,
          qestion: "使用する端末のOSは何でしょうか？",
          answer: "WindowsまたはMacでご希望の方の端末をご用意します。",
        },
        {
          id: 7,
          qestion: "業務中に車を運転することはありますか？",
          answer:
            "職種によってはあります。頻度はまれです。社有車があります。ご自身のお車を使う場合は手当を支給しています。業務中にお車の運転ができないことを前提とした応募も可能ですので、応募書類にその旨をご記載ください。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.job-category-top {
  .blue-back {
    background-color: var(--main);
    .blue-inner {
      position: relative;
      padding-top: 100px;
      padding-bottom: 150px;
      @media screen and (max-width: 767px) {
        margin-top: 70px;
      }
      h1,
      p {
        color: white;
      }
    }
  }
  .top {
    margin-top: -120px;
  }
  .text {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
    @media screen and (max-width: 767px) {
      display: block;
    }
    p {
      width: 80%;
      margin-bottom: 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    img {
      width: 250px;
      @media screen and (max-width: 767px) {
        width: 200px;
        margin-left: calc(100% - 200px);
      }
    }
  }
  .illust-b {
    width: 200px;
    position: absolute;
    top: 60%;
    left: 15%;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.recommendation {
  background-color: var(--sub);
}

.detail{
  margin-top:50px;
}
</style>