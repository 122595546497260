var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"job-category-info wrap"},[_c('UiHeading',{attrs:{"h":'2',"type":'A',"text":'Job Conditions',"subTitle":'求人情報'}}),_c('div',{staticClass:"table-inner"},[_c('table',[_c('tr',[_c('th',[_vm._v("職種")]),_c('td',[_vm._v(_vm._s(_vm.job))])]),_vm._m(0),_c('tr',[_c('th',[_vm._v("業務内容")]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.content)}})]),_vm._m(1),_c('tr',[_c('th',[_vm._v("応募条件")]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.condition)}})]),_vm._m(2),_c('tr',[_c('th',[_vm._v("時給")]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.pay)}})]),_c('tr'),_c('tr',[_c('th',[_vm._v("昇給実績")]),_c('td',[_vm._v(" "+_vm._s(_vm.raise)+" ")])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.pg === true),expression:"pg === true"}]},[_vm._v(" プログラマー（経験者）は、スキルと勤務評価に応じて部分的な在宅勤務を認めています。 ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.wdn === true),expression:"wdn === true"}]},[_vm._v(" ウェブデザイナー（経験者）は、スキルと勤務評価に応じて部分的な在宅勤務を認めています。 ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.first === true),expression:"first === true"}]},[_vm._v(" 当求人に応募を希望の場合は、当ページに記載の内容を必ずすべてお読みいただき、仕事内容や応募条件をご理解の上、ご応募をお願いします。 ")])]),_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#contact'),expression:"'#contact'"}],staticClass:"detail-button",attrs:{"href":"#"}},[_vm._v("応募する")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("雇用形態")]),_c('td',[_vm._v("パートタイム・アルバイト（雇用期間の定めなし）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("勤務場所")]),_c('td',[_vm._v("岐阜市加納桜田町2-5-2（転勤なし）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("必要な資格")]),_c('td',[_vm._v("普通自動車免許（AT限定可）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("通勤手当")]),_c('td',[_vm._v("実費支給（マイカー通勤・駐車場完備）"),_c('br'),_vm._v("※ご自宅からの距離に応じたガソリン代を支給。公共交通機関・自転車・徒歩での通勤の場合も、ご自宅からの距離に応じたガソリン代で交通費を支給となります。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("勤務時間")]),_c('td',[_vm._v(" 平日9時30分～17時のご希望の曜日・時間帯で週12時間以上（※月間120時間未満） ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("会社年間休日")]),_c('td',[_vm._v("120日以上（土日祝、年末年始）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("試用期間")]),_c('td',[_vm._v("300時間（勤務態度・評価によって延長の可能性あり）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("加入保険")]),_c('td',[_vm._v("雇用保険（週20時間以上の勤務の場合）")])])
}]

export { render, staticRenderFns }